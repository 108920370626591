.element {
    padding: 2px;
    display: flex;
    justify-content: flex-start;
    position: relative;
    border: 1px solid transparent;
}

.element:hover {
    background-color: #ffffff;
    border: 1px solid #efefef;
}

.sorter {
    margin-right:20px;
    flex: 0 0 40px;
    cursor: all-scroll ;
}

.action {
    right:0px;
    position:absolute;
    top:0;
    background-color: #0000002c;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    float: left;
}

.formitem {
    flex: 1 1 auto;
}