@import url("https://fonts.googleapis.com/css2?family=Ubuntu&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

:root {
  /* Primary color */
  --color-primary-default: #1f4e88;
  --color-primary-light: #2679bd;
  --color-primary-dark: #14345b;
  --color-primary-ultralight: #96beee;
  /* ... */
  --color-primary-blue: #2563eb;
  --color-primary-grey: #a6a9c8;
  --color-primary-grey-title: #827f9b;
  --color-primary-grey-background: #f5f5f9;
  /* Secondary color */
  --color-secondary-lila: #8f9dff;
  --color-secondary-blueGreenPastel: #d5f0f6;
  --color-secondary-topePink: #e4d9e6;
  --color-secondary-pastelPink: #ffccd2;
  --color-secondary-yellowLemon: #fff7ab;
  --color-secondary-abricot: #ffe2c8;
  --color-secondary-greenOnline: #34c7a4;
  /* CUSTOM DELETE */
  --color-delete: #ef4444;
}
@tailwind base;
@tailwind components;
@tailwind utilities;
@import '~antd/dist/antd.css';
.flexCenter {
  @apply flex  justify-center items-center;
}

.flexRowCenter {
  @apply flex flex-row justify-center items-center;
}

.flexColCenter {
  @apply flex flex-col justify-center items-center;
}

.Center {
  @apply justify-center items-center;
}

.flexColSpace {
  @apply flex flex-col justify-between;
}



/**
**     GlOBAL UI CSS
*/
* {
  font-family: "Ubuntu", sans-serif;
  box-sizing: border-box;
}

body {
  margin: 0;
  box-sizing: border-box;
  font-family: "Ubuntu", sans-serif;
  background-color: var(--color-primary-grey-background);

  background-image: url("./assets/backgroundTach.svg");
  background-repeat: no-repeat;
  background-position: 0 10vh;
  background-attachment: fixed;
}
.ant-drawer-content{
  border-radius: 10px;

}
.ant-tabs-nav-list{
flex-wrap: wrap!important;
}
.ant-layout-conten{
  border-radius: 10px;
  box-shadow: gray;
}
.ant-select-selector{
  border: none !important;
  background: white;
  height: 100% !important;
}

.c-card {

  img {    
    transition: transform .3s ease-in-out; 
  }
  
  &:hover {
    .OneForm {
      transform: scale(1.05)
    }
  }
}

*::-webkit-scrollbar {
  width: 5px;
}

*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(69, 101, 246, 0.3);
  border-radius: 10px;
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(69, 101, 246, 0.5);
}

.bg-gray {
  background-color: #f5f5f9;
}

.color-white {
  color: #fff !important;
}

.check {
  font-size: 14px;
  line-height: 16px;
  color: #a6a9c8;
  &:first-child {
    margin-left: 6px;
  }
}

.maj {
  font-size: 13px;
  line-height: 17px;
  color: #a6a9c8;
}

h1 {
  font-size: 36px;
  font-weight: bold;
}

h2 {
  font-size: 26px;
  font-weight: bold;
}

h3 {
  font-size: 20px;
  color: var(--color-primary-grey-title);
}

.menu {
  font-size: 16px;
  font-weight: 500;
}
.menuInput {
  font-size: 16px;
  line-height: 18px;
  color: #a6a9c8;
}

.card-title {
  font-size: 18px;
  font-weight: bold;
}

.card-subtitle {
  font-size: 14px;
  color: var(--color-primary-grey);
}

.courant-text {
  font-size: 16px;
}

.text-primary-blue {
  color: var(--color-primary-blue);
}

.border-blue {
  border: 2px solid var(--color-primary-blue);
}

.green-tick {
  color: var(--color-secondary-greenOnline) !important;
}

.boxSpecialite {
  width: 158px;
  min-height: 35px;
  background: rgba(166, 169, 200, 0.35) !important;
  border-radius: 9px;
  p {
    font-size: 14px;
    line-height: 16px;
    color: #a6a9c8;
  }
  @media screen and (max-width: 500px) {
    width: 100%;
  }
}
.disabled-box {
  flex-grow: 0;
  padding: 10px 12px 9px 14px;
  border-radius: 9px;
  background-color: rgba(166, 169, 200, 0.22);
  font-size: 14px;
  color: var(--color-primary-grey);
}
.active-box {
  flex-grow: 0;
  padding: 10px 12px 9px 14px;
  border-radius: 9px;
  background-color: var(--color-primary-blue);
  font-size: 14px;
  color: var(--color-primary-grey-background);
}
.box {
  flex-grow: 0;
  padding: 10px 12px 9px 14px;
  border-radius: 9px;
  background-color: rgba(166, 169, 200, 0.22);
  font-size: 14px;
  color: var(--color-primary-grey);
}

.gray-box-icon {
  padding: 11.7px 11.7px 11.7px 11.7px;
  background-color: #ebecf3;
  flex-grow: 0;
  border-radius: 50%;
}

.link {
  font-size: 16px;
  font-weight: 500;
  color: var(--color-primary-grey-title);

  &:active {
    color: var(--color-primary-blue);

    font-weight: bold;
  }
  &:hover {
    color: var(--color-primary-blue);
  }
  &:disabled {
    color: #ddd;
  }
}

/**
 * ----------------------------------------
 * animation slide-left
 * ----------------------------------------
 */
.slide-left {
  -webkit-animation: slide-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
@-webkit-keyframes slide-left {
  0% {
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes slide-left {
  0% {
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

.p0 {
  padding: 0 !important;
}
.px0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}
.py0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.next-rdv {
  p {
    background: #ffffff;
    border: 2px solid #4565f6;
    border-radius: 9px;
    padding: 10px 27px 10px 28px;
  }
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #1d1d1b;
}
.fc-button-primary {
  color: var(--color-primary-blue) !important;
  border-radius: 15px !important;
  border: solid 1px #eaecf4 !important;
  background-color: #fff !important;
  width: 135px !important;
  height: 50px !important;
  font-weight: bold !important;
  &:active {
    border: solid 1px #cdced1 !important;
    outline: none !important;
  }
}

.fc-toolbar-chunk {
  display: flex !important;
}

.fc-toolbar-title {
  font-size: 36px !important;
  font-weight: bold !important;
  color: #000 !important;
  flex: 1;
}

.fc-next-button,
.fc-prev-button {
  width: 50px !important ;
  height: 50px !important;
}

.hr-m-0 {
  margin: 0 !important;
}

.bg-opacity-modal {
  background-color: rgba(130, 127, 155, 0.7);
}
.bg-opacity-agenda {
  background-color: rgba(130, 127, 155, 0.07);
}

.btn-delete {
  color: #ef4444 !important;
  border: 1px solid #ef4444 !important;
}
.stylingTextArea {
  textarea {
    background: rgba(255, 255, 255, 0.5);
    border: 1px solid #a6a9c8;
    box-sizing: border-box;
    border-radius: 9px;
  }
}

.anEmptyBox {
  min-width: 200px;
  min-height: 100px;
}

.max-h-screen-100 {
  max-height: calc(100vh - 100px);
}

.react-time-picker__clear-button,
.react-time-picker__clock-button,
.react-time-picker__clock,
.react-time-picker__clock--open {
  display: none !important;
}
.react-time-picker__wrapper {
  border: none !important;
}
.react-time-picker {
  color: #4565f6 !important;
  background: #ffffff !important;
  border-radius: 10px !important;
  width: 47px !important;
  height: 37px !important;
  font-weight: bold !important;
  font-size: 13px !important;
  line-height: 15px !important;
  margin-left: 6px !important;
  margin-right: 6px !important;
}

.mirror {
  display: block;
  -webkit-transform: matrix(-1, 0, 0, 1, 0, 0);
  -moz-transform: matrix(-1, 0, 0, 1, 0, 0);
  -o-transform: matrix(-1, 0, 0, 1, 0, 0);
  transform: matrix(-1, 0, 0, 1, 0, 0);
}

.switcher {
  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #2196f3;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}

.photo-profile:hover {
  .hide {
    display: block;
  }
}

.last-row {
  content: "";
  flex: auto;
}

// date picker select year
.react-datepicker__navigation--years-upcoming::after {
  content: "";
  position: absolute;
  right: 35%;
  bottom: 3px;
  width: 10px;
  height: 10px;
  border-right: 5px solid rgba(160, 161, 163, 0.6);
  border-bottom: 5px solid rgba(160, 161, 163, 0.6);
  transform: rotate(-135deg);
}
.react-datepicker__navigation--years-previous::after {
  content: "";
  position: absolute;
  right: 35%;
  top: 3px;
  width: 10px;
  height: 10px;
  border-right: 5px solid rgba(160, 161, 163, 0.6);
  border-bottom: 5px solid rgba(160, 161, 163, 0.6);
  transform: rotate(45deg);
}

.katomiWterMark {
  z-index: -1;
  opacity: 0.7;
  background-image: url("./assets/backgroundTach.svg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  @media screen and (max-width: 1000px) {
    background-position: 50% 50%;
    background-size: 100%;
  }
  @media screen and (max-width: 1199px) and (min-width: 1001px) {
    background-position: 3% 50%;
    background-size: 28%;
  }
  @media screen and (min-width: 1200px) {
    background-position: 8% 50%;
    background-size: 30%;
  }
}

.katomiWterMark2 {
  z-index: 1;
  opacity: 0.1;
  background-image: url("./assets/backgroundTach.svg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: 50% 50%;
  background-size: 40%;
}
