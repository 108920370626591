.item {
    cursor: pointer;
    list-style: none;
    margin: 5px;
    padding: 10px;
    border: 1px dashed #ddd;
    display: flex;
    justify-content: flex-start 
}
.item > i {
    margin-right: 10px;
    padding-top:5px;
}
.item > div {
    margin-right: 10px
}